@use "mixins" as *;
@import 'primeicons/primeicons.css';

.calendar__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.calendar__empty-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border: .4px solid var(--color-border-tertiary);
  border-radius: var(--radius);
  background-color: var(--content-bg--color-lighter);
  padding: 1rem;
  min-width: 35rem;
  min-height: 15rem;
  overflow: hidden;
}

.calendar__empty-icon {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-10%, -25%);
  color: var(--color-icon);
  opacity: .1;
  z-index: 1;
}

.calendar__empty-header {
  @include subtitle;
}

.calendar__empty-button {
  @include subtitle;
  background-color: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  padding: .4rem .8rem;
  border-radius: var(--radius);
  cursor: pointer;
  z-index: 2;
  opacity: 1;

  &:hover {
      background-color: var(--color-button-bg-submit-full-hover);
      color: var(--color-text-quaternary);
      opacity: 1;
  }
}

.calendar__empty-add {
  width: 1.6rem;
  height: 1.6rem;
}

#calendar-app {
  display: flex;
  width: 100%;
  height: 100%;
}

.p-splitbutton,
.button {
  padding: 0 .4rem;
  border-radius: var(--radius);
  gap: .8rem;
  min-width: 10rem;
  justify-content: space-between;

  &.-full {
    background-color: var(--color-bg-warning-light);
    color: var(--color-text-warning);
  }

  &.-incomplete {
    background-color: var(--color-bg-success-dark) !important;
    color: var(--color-text-success-light) !important;
  }
}

.p-button-label {
  @include subtitle;
}

.p-splitbutton.-full > .p-splitbutton-button > .p-button-label,
.p-tieredmenu-item.-full > .p-tieredmenu-item-content > .p-tieredmenu-item-link > .p-tieredmenu-item-icon {
  color: var(--color-text-warning);
}

.p-splitbutton.-incomplete > .p-splitbutton-button > .p-button-label,
.p-tieredmenu-item.-incomplete > .p-tieredmenu-item-content > .p-tieredmenu-item-link > .p-tieredmenu-item-icon {
  color: var(--color-text-success-light);
}

.p-tieredmenu-item.-incomplete > .p-tieredmenu-item-content:hover {
  background-color: var(--color-bg-success-dark) !important;
  color: var(--color-text-success-light) !important;
}

.p-tieredmenu-item.-full > .p-tieredmenu-item-content:hover {
  background-color: var(--color-bg-warning-light) !important;
  color: var(--color-text-warning) !important;
}

.p-button {
  background: transparent;
  border: none;
  padding: 0;
  color: currentColor;
  overflow: visible;
}

.p-button:not(:disabled):hover {
  background: transparent;
  border: none;
  color: currentColor;
}

.p-splitbutton-button {
  cursor: auto;
  gap: .4rem;
}

.p-tieredmenu {
  min-width: 0px;
  color: transparent;
  background: transparent;
  border: none;
}

.p-tieredmenu-item {
  @include subtitle;
  border-radius: var(--radius);
  gap: .8rem;
  padding: .4rem;
  margin-top: .4rem;

  &.-full {
    background-color: var(--color-bg-warning-light);
    color: var(--color-text-warning);
  }

  &.-incomplete {
    background-color: var(--color-bg-success-dark);
    color: var(--color-text-success-light);
  }
}

.p-tieredmenu-item-link {
  gap: .4rem;
  padding: 0;
}

.p-splitbutton-dropdown {
  color: var(--color-text-quaternary);
}

.p-chart {
  width: 100%;
  height: 100%;
}

.p-tieredmenu-item.-incomplete > .p-tieredmenu-item-content {
  background-color: var(--color-bg-success-dark) !important;
  color: var(--color-text-success-light) !important;
  transition: none;
}

.p-tieredmenu-item.-full > .p-tieredmenu-item-content {
  background-color: var(--color-bg-warning-light) !important;
  color: var(--color-text-warning) !important;
  transition: none;
}

.p-tieredmenu-root-list {
  padding: 0;
}

.p-tieredmenu-item-content,
.p-tieredmenu-item-content:hover {
  background: transparent !important;
  color: var(--color-text-primary) !important;
  transition: none !important;
}

.p-button-text.p-button-secondary:not(:disabled):hover {
  background: var(--color-button-bg-focus);
  border: none;
  color: var(--color-text-primary);
}

.submit-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.2rem;
}

.submit {
  @include body;
  background: var(--color-button-bg-submit);
  color: var(--color-text-quaternary);
  border: none;
  padding: .4rem .8rem;
  border-radius: var(--radius);

  &:hover {
    background: var(--color-button-bg-submit-full-hover);
    color: var(--color-text-quaternary);
  }
}

.car__delay-work-icon {
  width: 1rem;
  height: 1rem;

  &:hover {
      cursor: pointer;
  }
}

.calendar-message {
  z-index: 100000;
  position: absolute;
  margin-top: .5rem;
  width: fit-content;
}

.document-preview {
  position: fixed;
  right: 2rem;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
